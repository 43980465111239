import { useAtomValue } from "jotai";
import React, { lazy } from "react";

import {
  currentStepAtom,
  ValuationProcessStep,
} from "../state/valuationProcessState";

type ReactNode = React.ReactNode;
const empty = null;

const SessionsHeader = lazy(
  () => import("~/sessions/components/SessionsHeader"),
);

const BoundariesHeader = lazy(
  () => import("~/boundaries/components/Header/BoundariesHeader"),
);

const CompRecommendationHeader = lazy(
  () =>
    import("~/compRecommendation/components/Header/CompRecommendationHeader"),
);

const stepComponentMapping: Record<ValuationProcessStep, ReactNode> = {
  [ValuationProcessStep.Sessions]: <SessionsHeader />,
  [ValuationProcessStep.Boundaries]: <BoundariesHeader />,
  [ValuationProcessStep.CompRecommendation]: <CompRecommendationHeader />,
  [ValuationProcessStep.CompRec]: empty,
  [ValuationProcessStep.MarketAnalysis]: empty,
  [ValuationProcessStep.Adjustments]: empty,
  [ValuationProcessStep.Reconciliation]: empty,
  [ValuationProcessStep.Export]: empty,
};

export default function TrueTractsHeader() {
  const currentStep = useAtomValue(currentStepAtom);
  const Component = stepComponentMapping[currentStep];

  if (Component == null) {
    return null;
  }

  return Component;
}
