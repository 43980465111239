import React from "react";
import { ErrorBoundary } from "react-error-boundary";
interface LoadingProps {
  children: React.ReactNode;
  suspenseFallback?: React.ReactNode;
}

export default function Loading({ children, suspenseFallback }: LoadingProps) {
  return (
    <ErrorBoundary fallback={<>Error!</>}>
      <React.Suspense fallback={suspenseFallback ?? <>Loading...</>}>
        {children}
      </React.Suspense>
    </ErrorBoundary>
  );
}
