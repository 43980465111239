import { Gite } from "@mui/icons-material";
import { lazy } from "react";

import { ValuationProcessStep } from "~/common/state/valuationProcessState";
import { SiteConfiguration } from "./domain/SiteConfiguration";
import { internalNavigationButtons } from "./internalNavigationButtons";

const SessionsHeader = lazy(
  () => import("~/sessions/components/SessionsHeader"),
);

const BoundariesHeader = lazy(
  () => import("~/boundaries/components/Header/BoundariesHeader"),
);
const CompRecommendationHeader = lazy(
  () =>
    import("~/compRecommendation/components/Header/CompRecommendationHeader"),
);

export default {
  header: {
    icon: <Gite />,
    title: "TrueTracts",
    components: {
      [ValuationProcessStep.Sessions]: <SessionsHeader />,
      [ValuationProcessStep.Boundaries]: <BoundariesHeader />,
      [ValuationProcessStep.CompRecommendation]: <CompRecommendationHeader />,
      [ValuationProcessStep.CompRec]: null,
      [ValuationProcessStep.MarketAnalysis]: null,
      [ValuationProcessStep.Adjustments]: null,
      [ValuationProcessStep.Reconciliation]: null,
      [ValuationProcessStep.Export]: null,
    },
  },
  navBar: {
    buttons: internalNavigationButtons,
  },
  body: {
    componentOverrides: {
      [ValuationProcessStep.Sessions]: null,
      [ValuationProcessStep.Boundaries]: null,
      [ValuationProcessStep.CompRecommendation]: null,
      [ValuationProcessStep.CompRec]: null,
      [ValuationProcessStep.MarketAnalysis]: null,
      [ValuationProcessStep.Adjustments]: null,
      [ValuationProcessStep.Reconciliation]: null,
      [ValuationProcessStep.Export]: null,
    },
  },
  modules: {
    boundaries: {
      showResidualData: true,
      showZipcodeOverlay: true,
      heatmap: {
        resolution: 200,
        filterFactor: 6,
        desiredHomeSales: 15000,
      },
    },
  },
} satisfies Readonly<SiteConfiguration>;
