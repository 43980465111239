import { atom, useAtomValue } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";

export interface FeatureFlag {
  label: string;
  state: boolean;
}

export type FeatureFlagId =
  | "similarClustersEnabled"
  | "similarClustersAlt"
  | "enableNewCompRec"
  | "enableNewMarketAnalysis"
  | "enablePriceEstimates"
  | "addJitterToStaticClusters"
  | "enableHeatmapSettings";

export const FeatureFlagDefinitions: Record<FeatureFlagId, FeatureFlag> = {
  similarClustersEnabled: {
    label: "Enable Similar Clusters tab",
    state: false,
  },
  similarClustersAlt: {
    label: "Alternative Similar Clusters tab",
    state: false,
  },
  enableNewCompRec: {
    label: "Enable new comp recommendation page",
    state: false,
  },
  enableNewMarketAnalysis: {
    label: "Enable new market analysis page",
    state: false,
  },
  enablePriceEstimates: {
    label: "Enable Price Estimates tab",
    state: false,
  },
  addJitterToStaticClusters: {
    label: "Add jitter to property listings in static clusters",
    state: false,
  },
  enableHeatmapSettings: {
    label: "Enable heatmap settings panel",
    state: false,
  },
} as const;

function featureFlagLocalStorageKey(featureFlagId: FeatureFlagId) {
  return `settings.${featureFlagId}`;
}

const featureFlagFamily = atomFamily((featureFlagId: FeatureFlagId) =>
  atomWithStorage<boolean>(
    featureFlagLocalStorageKey(featureFlagId),
    FeatureFlagDefinitions[featureFlagId].state,
    undefined,
    {
      getOnInit: false,
    },
  ),
);

export const useFeatureFlagAtomValue = (featureFlagId: FeatureFlagId) => {
  const atom = featureFlagFamily(featureFlagId);
  return useAtomValue(atom);
};

export const setFeatureFlagStateAtom = atom(
  null,
  (_get, set, featureFlagId: FeatureFlagId, newState: boolean) => {
    const atom = featureFlagFamily(featureFlagId);
    set(atom, newState);
  },
);
